<template>
  <div>
    <h2 v-html="$t('search_localunit_title')"></h2>
    <v-text-field
      id="search_localunit_berno"
      v-model="searchCardStore.LocalUnit_berNo"
      :label="$t('search_localunit_berno')"
      type="text"
      @keyup.enter="search"
    />
    <v-text-field id="search_localunit_name" v-model="searchCardStore.LocalUnit_name" :label="$t('search_localunit_name')" type="text" @keyup.enter="search" />
    <v-text-field
      id="search_localunit_address"
      v-model="searchCardStore.LocalUnit_address"
      :label="$t('search_localunit_address')"
      type="text"
      @keyup.enter="search"
    />
    <v-text-field id="search_localunit_zip" v-model="searchCardStore.LocalUnit_zip" :label="$t('search_localunit_zip')" type="text" @keyup.enter="search" />
    <v-text-field
      id="search_localunit_locality"
      v-model="searchCardStore.LocalUnit_locality"
      :label="$t('search_localunit_locality')"
      type="text"
      @keyup.enter="search"
    />

    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <v-text-field
        id="search_localunit_egid"
        v-model="searchCardStore.LocalUnit_egid"
        :label="$t('search_localunit_egid')"
        type="number"
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { useSearchLocalUnitStore } from '@/store/SearchLocalUnitStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchLocalUnit',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchLocalUnitStore(), true)
  }
})
</script>
